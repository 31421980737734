<template>
  <aswitch
    :fieldAttributes="fieldAttributes"
    :fieldAttrInput="fieldAttributes.fieldAttrInput"
    :field="field"
    :rules="[]"
    :value="localValue"
    :templateContent="result"
    @input="updateValue"
  ></aswitch>
</template>
<script>
import aswitch from "@/commonComponents/aswitch.vue";
export default {
  components: { aswitch },
  props: ["result", "fieldAttributes", "field", "value"],
  computed: {
    localValue() {
      return this.value.start;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", {
        start: value,
      });
    },
  },
};
</script>